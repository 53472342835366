type AnyType = object | string | null | undefined;

export function isEmpty(value: AnyType): boolean {
  return (
    value == null || // From standard.js: Always use === - but obj == null is allowed to check null || undefined
    (typeof value === "object" && Object.keys(value).length === 0) ||
    (typeof value === "string" && value.trim().length === 0)
  );
}

export function isNotNull<T>(value: T | null | undefined): value is T {
  return value != null;
}

export function isFunction(value: AnyType): boolean {
  return Boolean(
    value &&
      (Object.prototype.toString.call(value) === "[object Function]" ||
        "function" === typeof value ||
        value instanceof Function)
  );
}

export function isEquals(x: AnyType, y: AnyType): boolean {
  if (x === null || x === undefined || y === null || y === undefined) {
    return x === y;
  }
  // after this just checking type of one would be enough
  if (x.constructor !== y.constructor) {
    return false;
  }
  // if they are functions, they should exactly refer to same one (because of closures)
  if (x instanceof Function) {
    return x === y;
  }
  // if they are regexps, they should exactly refer to same one (it is hard to better equality check on current ES)
  if (x instanceof RegExp) {
    return x === y;
  }
  if (x === y || x.valueOf() === y.valueOf()) {
    return true;
  }
  if (Array.isArray(x) && Array.isArray(y) && x.length !== y.length) {
    return false;
  }

  // if they are dates, they must had equal valueOf
  if (x instanceof Date) {
    return false;
  }

  // if they are strictly equal, they both need to be object at least
  if (!(x instanceof Object)) {
    return false;
  }
  if (!(y instanceof Object)) {
    return false;
  }

  // recursive object equality check
  const p = Object.keys(x);
  return (
    Object.keys(y).every(function(i: string): boolean {
      return p.indexOf(i) !== -1;
    }) &&
    p.every(function(i: string): boolean {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return isEquals((x as any)[i], (y as any)[i]);
    })
  );
}

export function isString(str: unknown): str is string {
  return typeof str === "string";
}

export function isDevEnv(): boolean {
  return process.env.NODE_ENV === "development";
}
