import Head from "next/head";
import * as React from "react";

const LayoutHead: React.FC = () => {
  return (
    <Head>
      <title>Pear Deck Tutor Admin Dashboard</title>
    </Head>
  );
};

export default LayoutHead;
