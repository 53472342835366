import * as React from "react";

import { isString } from "~/utils/common";

const LoadingIndicatorCaption: React.FC<{ caption?: React.ReactNode }> = ({
  caption
}) => {
  if (isString(caption)) {
    return <div className="h2">{caption}</div>;
  }

  if (React.isValidElement(caption)) {
    return caption;
  }

  return null;
};

export default LoadingIndicatorCaption;
