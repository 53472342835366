import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import * as React from "react";

import ColorLogo from "../../../assets/images/Logo_PearDeckTutor_color.svg";
import LayoutHead from "../common/LayoutHead";
import { TestIDUtils } from "../common/TestIdUtils";

interface Props {
  children: React.ReactElement | React.ReactElement[] | string;
}

const AuthLayout: React.FC<Props> = ({ children }) => (
  <>
    <LayoutHead />
    <main className="flex flex-col items-center justify-center h-screen bg-cream-100 paragraph">
      <header className="mb-[20px]">
        <ColorLogo className="h-[52px] w-[317px]" />
        <Typography className="ml-[44px] mt-[-12px]">
          Formerly TutorMe
        </Typography>
      </header>
      <Paper
        className="min-h-[400px] overflow-hidden p-[45px] relative w-[535px] border-2 border-cream-300"
        elevation={0}
      >
        {children}
      </Paper>
    </main>
    <TestIDUtils />
  </>
);

export default AuthLayout;
