import React from "react";

export const AuthContext = React.createContext<{
  user: IUser;
  setUser: (user: IUser) => void;
  selectedUmbrella: IUmbrellaAccount;
  onSelectUmbrella: (value: IUmbrellaAccount) => void;
  accessToken: string;
  signOut: () => void;
  readRequestHeaders: HeadersInit;
  writeRequestHeaders: HeadersInit;
  schoolYearStartDate: string;
  setSchoolYearStartDate: (date: string) => void;
}>(undefined as never);

export function useRequiredAuthContext() {
  const context = React.useContext(AuthContext);
  if (typeof context === "undefined") {
    throw new Error("AuthContext isnt available");
  }
  return context;
}

export function useOptionalAuthContext() {
  const context = React.useContext(AuthContext);
  if (typeof context === "undefined") {
    return undefined;
  }
  return context;
}
