import styled from "@emotion/styled";
import structPackageJSON from "@tutorme/sitestruct/package.json";
import { debounce } from "lodash";
import React, { useEffect, useState } from "react";
import {
  useCopyToClipboard,
  useLocalStorage,
  useMountedState
} from "react-use";

declare global {
  interface Window {
    showTestIds: undefined | (() => void);
  }
}

export const TestIDUtils = () => {
  const isMounted = useMountedState();
  const [shouldRender, setShouldRender] = useState(false);
  const [, copyToClipboard] = useCopyToClipboard();
  const [rects, setRects] = useState<Array<{ rect: DOMRect; name: string }>>(
    []
  );
  const [show, setShow] = useLocalStorage("showTestIds", false);

  useEffect(() => {
    setShouldRender(true);
  }, []);

  useEffect(() => {
    window.showTestIds = () => {
      setShow(!show);
    };
    return () => {
      window.showTestIds = undefined;
    };
  }, [setShow, show]);

  useEffect(() => {
    if (show) {
      const callback = debounce(
        () => {
          if (isMounted()) {
            setRects(
              Array.from(document.querySelectorAll("[data-testid]")).map(
                el => ({
                  rect: el.getBoundingClientRect(),
                  name: el.getAttribute("data-testid") ?? "unknown"
                })
              )
            );
          }
        },
        16,
        { maxWait: 16, trailing: true }
      );
      window.addEventListener("scroll", callback);
      window.addEventListener("resize", callback);
      const observer = new MutationObserver(callback);
      observer.observe(document.body, {
        attributes: true,
        childList: true,
        subtree: true
      });
      callback();
      return () => {
        window.removeEventListener("scroll", callback);
        window.removeEventListener("resize", callback);
        observer.disconnect();
      };
    }
    return undefined;
  }, [show]);

  const copy = (name: string) => () => copyToClipboard(name);

  if (!show || !shouldRender) {
    return null;
  }

  return (
    <>
      <VersionDisplay onClick={copy(structPackageJSON.version)}>
        version {structPackageJSON.version}
      </VersionDisplay>
      {rects.map(({ rect, name }, idx) => (
        <ElementRoot key={idx} style={{ top: rect.top, left: rect.left }}>
          <ElementBounds style={{ width: rect.width, height: rect.height }} />
          <ElementName
            style={{ top: rect.top < 30 ? rect.height - 1 : undefined }}
            onClick={copy(name)}
          >
            {name}
          </ElementName>
        </ElementRoot>
      ))}
    </>
  );
};

const VersionDisplay = styled.div`
  position: fixed;
  top: 10px;
  left: 10px;
  opacity: 0.5;
  z-index: 66666667;
  padding: 2px 4px;
  color: white;
  background: #f43f5e;
  cursor: crosshair;
`;

const ElementRoot = styled.div`
  opacity: 0.5;
  position: fixed;
  z-index: 66666667;
`;

const ElementBounds = styled.div`
  pointer-events: none;
  border: 1px solid #f43f5e;
  position: absolute;
  inset: -1px -1px -1px -1px;
`;

const ElementName = styled.div`
  position: absolute;
  top: -21px;
  left: -1px;
  padding: 2px 4px;
  color: white;
  background: #f43f5e;
  font-size: 12px;
  line-height: 16px;
  user-select: none;
  cursor: crosshair;
`;
