import { RefObject, useCallback, useEffect, useRef } from "react";

import { useIsomorphicLayoutEffect } from "~/utils/useIsomorphicLayoutEffect";

const SCROLL = "scroll";
const PADDING_TOP = "padding-top";

const addOffsetToLoaderRef = (loaderRef: RefObject<HTMLDivElement>): void => {
  const loaderEl = loaderRef?.current;

  if (!loaderEl) {
    return;
  }

  const loaderRect = loaderEl?.getBoundingClientRect();
  const loaderTop = loaderRect?.top || 0;
  const loaderHeight = loaderRect?.height || 0;
  const viewportHeight = window.innerHeight;
  const loaderOffsetFromTop = loaderTop + window.pageYOffset;
  const loaderBottom = loaderOffsetFromTop + loaderHeight;
  const windowScrollY = window.scrollY;
  const scrolledBottom = windowScrollY + viewportHeight;
  const topBoundary = loaderTop > windowScrollY ? loaderTop : windowScrollY;
  const bottomBoundary =
    loaderBottom > scrolledBottom ? scrolledBottom : loaderBottom;
  const offsetDelta =
    loaderOffsetFromTop > windowScrollY
      ? 0
      : windowScrollY - loaderOffsetFromTop;

  const loaderTopOffset = (bottomBoundary - topBoundary) / 2 + offsetDelta;

  loaderEl?.style.setProperty(PADDING_TOP, `${loaderTopOffset}px`);
};

export function useInViewportOffset(
  indicatorInViewport?: boolean
): React.RefObject<HTMLDivElement> {
  const loaderRef = useRef<HTMLDivElement>(null);

  const handleScroll = useCallback((): void => {
    addOffsetToLoaderRef(loaderRef);
  }, []);

  useEffect(() => {
    if (indicatorInViewport) {
      handleScroll();

      window.addEventListener(SCROLL, handleScroll);
    }

    return (): void => {
      window.removeEventListener(SCROLL, handleScroll);
    };
  }, [handleScroll, indicatorInViewport]);

  useIsomorphicLayoutEffect(() => {
    if (loaderRef && indicatorInViewport) {
      addOffsetToLoaderRef(loaderRef);
    }
  }, [indicatorInViewport, loaderRef]);

  return loaderRef;
}
